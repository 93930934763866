<template>
  <v-container>
    <v-dialog
      v-model="modalMasterStatus"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      scrollable
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ title || $t("basic_competencies.add_basic_competencies") }}
          <span v-if="formType != 'weight'" class="ml-2"
            >{{ competencies.lesson }}
            <span>{{ competencies.sub_lesson }}</span></span
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form
            ref="form"
            v-model="masterCoreCompetenciesForm"
            lazy-validation
          >
            <v-row class="my-3">
              <!-- <v-col cols="12">
                <v-select
                  v-model="subject"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('basic_competencies.choose_subject')"
                  :items="subjectList"
                  item-text="lesson"
                  item-value="id_lesson"
                  :disabled="subjectList.length == 0"
                  :loading="loadingSubject"
                  :readonly="formType != 'add'"
                  dense
                  outlined
                  required
                  @change="getChapter()"
                />
              </v-col> -->
              <v-col cols="12">
                <v-expansion-panels
                  v-if="data.length > 0"
                  v-model="openPanel"
                  focusable
                  multiple
                >
                  <v-expansion-panel v-for="(r, i) in data" :key="i">
                    <v-expansion-panel-header>
                      <span>
                        {{ $t("basic_competencies.basic_competencies") }}:
                        <strong class="ml-1">{{ r.competencies }}</strong>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="r.description" no-gutters class="my-4">
                        <strong
                          >{{ $t("core_competencies.description") }}:</strong
                        >
                        <br />
                        <div>{{ r.description }}</div>
                      </div>
                      <v-divider v-if="r.description"></v-divider>
                      <v-card
                        flat
                        v-for="(c, ci) in dataChapter(r.kd)"
                        :key="ci"
                      >
                        <v-sheet>
                          <p class="font-weight-bold mt-4">
                            {{ $t("basic_competencies.chapter") }}
                            <!-- {{ i + 1 }}.{{ ci + 1 }} -->
                          </p>
                          <v-textarea
                            v-model="c.kd_chapter"
                            :label="
                              $t('basic_competencies.enter_the_chapter_name')
                            "
                            :readonly="formType == 'detail'"
                            dense
                            rows="1"
                            auto-grow
                            outlined
                            required
                          >
                            <v-icon
                              v-if="
                                chapterCondition(ci, r.kd, 'delete') &&
                                  formType != 'detail'
                              "
                              slot="append"
                              color="error"
                              @click="chapterHandler(i, ci, 'delete', c)"
                            >
                              mdi-delete
                            </v-icon>
                            <v-icon
                              v-if="
                                chapterCondition(ci, r.kd) &&
                                  formType != 'detail'
                              "
                              slot="append"
                              color="primary"
                              @click="
                                () => {
                                  chapterHandler(i, null, null, c);
                                }
                              "
                            >
                              mdi-plus-box
                            </v-icon>
                          </v-textarea>
                        </v-sheet>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-alert
                  v-if="data.length == 0 && subjectList.length > 0 && noDataKI"
                  color="error"
                  class="white--text"
                  dense
                  >{{ $t("basic_competencies.no_ki_created") }}</v-alert
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-layout justify-end class="ma-5">
            <v-btn
              class="mr-2"
              outlined
              color="primary"
              @click="closeDialog()"
              >{{ $t("app.back") }}</v-btn
            >
            <v-btn
              v-if="formType != 'detail'"
              :loading="loadingSave"
              :disabled="loadingSubject"
              class="gradient-primary"
              dark
              depressed
              @click="submit"
              >{{ $t("app.save") }}</v-btn
            >
            <v-btn
              v-if="formType == 'detail'"
              class="gradient-primary"
              dark
              depressed
              @click="changeFormType"
              >{{ $t("app.edit") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import {
  get_ki,
  save_competencies
} from "@/api/admin/academic/basicCompetencies";
// import { getSubjectsList } from "@/api/admin/academic/subjects";
export default {
  props: {
    title: String,
    formType: String,
    modalMasterStatus: Boolean,
    toggleDialogMaster: Function,
    loadingMaster: Object,
    changeFormType: Function,
    listMaster: Object,
    competencies: Object
  },
  data() {
    return {
      openPanel: [],
      tempKdData: [],
      modalStart: false,
      modalEnd: false,
      masterCoreCompetenciesForm: false,
      loadingSubject: false,
      subjectList: [],
      subject: null,
      data: [],
      loading: false,
      loadingSave: false,
      noDataKI: false
    };
  },
  watch: {
    async competencies(nv) {
      if (nv) {
        this.tempKdData = JSON.parse(JSON.stringify(nv.ki));
        const panelTemp = [];
        const kdTemp = [];

        await Promise.all(
          nv.ki.map((r, i) => {
            if (r.kd.length > 0) {
              const getKd = [];
              r.kd.map((p, j) => {
                getKd.push({ kd_title: j + 1, ...p });
              });

              kdTemp.push({
                id_competencies: r.id_competencies,
                competencies: r.competencies,
                description: r.description,
                kd: getKd
              });

              panelTemp.push(i);
            } else {
              kdTemp.push({
                id_competencies: r.id_competencies,
                competencies: r.competencies,
                kd: [{ id: null, kd_chapter: "" }]
              });
            }
          })
        );

        this.data = kdTemp;
        setTimeout(() => {
          this.openPanel = panelTemp;
        }, 50);
      }
    }
    // async modalMasterStatus(newVal) {
    //   this.loadingSubject = true;
    //   if (newVal) {
    //     const subject = await getSubjectsList({
    //       school_year: [],
    //       grade: []
    //     });
    //     this.subjectList = subject.data.data;
    //     this.loadingSubject = false;

    //     if (this.formType != "add") {
    //       this.subject = this.competencies.id;
    //     }
    //   }
    // }
  },
  methods: {
    dataChapter(ch) {
      return ch.filter(k => {
        return k.kd_chapter != null;
      });
    },
    getChapter() {
      this.loadingSubject = true;
      this.noDataKI = false;
      get_ki({ lesson: this.subject })
        .then(res => {
          if (res.data.code) {
            const temp = [];
            const panelTemp = [];
            res.data.data.map((r, i) => {
              temp.push({
                id_competencies: r.id,
                competencies: r.competencies,
                kd: [{ id: null, kd_chapter: "" }]
              });
              panelTemp.push(i);
            });
            this.data = temp;
            this.openPanel = panelTemp;
          }
          if (res.data.data.length == 0) this.noDataKI = true;
          this.loadingSubject = false;
        })
        .catch(() => (this.noDataKI = false));
    },
    closeDialog() {
      this.toggleDialogMaster();
      this.$refs.form.reset();
      this.$emit("getValue");
      this.data = [];
    },
    percentCondition(idx, data) {
      const weighting = this.data[idx].weighting;
      const index = weighting.findIndex(w => w.id == data.id);
      if (weighting[index].value >= 100) weighting[index].value = 100;
    },
    chapterCondition(subIndex, data, type) {
      let tempData = this.dataChapter(data);
      if (type) {
        return tempData.length > 0 && tempData.length != 1;
      } else {
        return tempData.length - 1 == subIndex;
      }
    },
    chapterHandler(kdIndex, index, del, data) {
      const chapter = this.data[kdIndex].kd;
      if (del == null) {
        chapter.push({
          id: null,
          kd_chapter: ""
        });
      } else if (del && data.id == null) {
        chapter.splice(index, 1);
      } else {
        data.kd_chapter = null;
      }
    },
    async submit() {
      const element = document.querySelector(".error--text");

      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        save_competencies({ data: this.data }, this.formType)
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("basic_competencies.save_ki_edit_success"),
                color: "success"
              });
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.closeDialog();
            this.loadingSave = false;
          })
          .catch(() => {
            this.loadingSave = false;
          });
      } else {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    }
  }
};
</script>
